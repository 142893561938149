import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
import { Message } from 'element-ui'

Vue.use(VueRouter)

import zhike from './zhike'
import homePage from './homePage'
import consult from './consultModule'
import stairRouter from './stairRouter'

const routerPath = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPath.call(this, location).catch((e) => e)
}

const routes = [
  //  一级页面路由
  ...stairRouter.stairRouter,
  {
    path: '/login/login',
    name: 'login',
    meta: { title: '登录页' },
    component: () => import('@/views/Login/login.vue')
  },
  {
    path: '/login/ForgetPsd',
    name: 'ForgetPsd',
    meta: { title: '忘记密码' },
    component: () => import('@/views/Login/comms/ForgetPsd.vue')
  },
  {
    path: '/login/register',
    name: 'register',
    meta: { title: '注册' },
    component: () => import('@/views/Login/comms/register.vue')
  },
  {
    // 404页面
    path: '/404',
    meta: { title: '404Page' },
    component: () => import('@/views/404')
  },
  {
    // 测试
    path: '/tests',
    meta: { title: 'tests' },
    component: () => import('/tests/tests.vue')
  },
  /*  {
    path: '/exam_practice/question_Tik',
    name: 'question_Tik',
    meta: { title: '题库列表集' },
    component: () => import('@/views/exam_practice/common/question_Tik.vue')
  }, */
  {
    path: '/consult',
    name: 'consult',
    redirect: '/consult/CFA',
    meta: { title: 'consult咨询模块' },
    component: () => import('@/views/consult/header.vue'),
    children: [...consult.consult]
  },
  {
    path: '/newzhike',
    name: 'newzhike',
    redirect: '/newzhike/courseOutline',
    meta: { title: 'newzhike-智课' },
    component: () => import('@/views/newzhike/topHeader.vue'),
    children: [...zhike.zhike]
  },
  {
    path: '/',
    name: 'layout',
    component: () => import('@/views/Layout/index.vue'),
    redirect: '/home',
    children: [...homePage.homePage]
  },

]

const router = new VueRouter({
  mode: 'hash',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

// 白名单
const whiteList = [
  '/login/login',
  '/login/register',
  '/login/ForgetPsd',
  '/404',
  '/courseCenter',
  '/home',
  '/handpick_kc',
  '/hot_live',
  '/course',
  // '/homes_tabs',
  '/activity',
  '/test1'
]

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  // to.matched.length 为0表示没有匹配到路由
  if (to.matched.length === 0) {
    // 如果未匹配到路由
    next('/404')
  } else {
    if (!localStorage.getItem('vuex_token') && !whiteList.includes(to.path)) {
      if (whiteList.includes(to.path)) {
        next('/login/login')
      } else {
        store.state.showLoginStatus = true
        Message({
          message: '暂未登录，去登录!',
          type: 'warning',
          iconClass: ' ',
          customClass: 'el-message--warning'
        })
        return
      }
    } else {
      next() // 如果匹配到正确跳转
    }
  }
})

export default router
