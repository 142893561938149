<template>
  <div class="content">
    <div class="login_box">
      <div class="login_left">
        <div class="closeBtn" @click="closeLogin()"></div>
        <div class="bg_logo">
          <img src="@/assets/images/logorongyue.png" alt="" />
          <p>融跃教育</p>
        </div>
      </div>
      <div class="login_right">
        <!-- 登录 -->
        <div class="login_form" v-if="loginShow == 1">
          <ul class="nav_tabs">
            <li :class="{ actives: actives_val == 0 }" @click="actives_val = 0">
              <span>账号登录</span>
            </li>
            <li :class="{ actives: actives_val == 1 }" @click="actives_val = 1">
              <span>短信登录</span>
            </li>
          </ul>

          <div class="tab_main">
            <!-- 账号登录 -->
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              class="demo_ruleForm"
              v-if="actives_val == 0"
            >
              <el-form-item prop="mobile">
                <el-input
                  v-model="ruleForm.mobile"
                  prefix-icon="el-icon-s-custom"
                  placeholder="请输入您的账号"
                ></el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input
                  placeholder="请输入您的密码"
                  v-model="ruleForm.password"
                  prefix-icon="el-icon-lock"
                  show-password
                ></el-input>
              </el-form-item>
              <el-form-item prop="graphCode">
                <div class="verification">
                  <el-input
                    v-model="ruleForm.graphCode"
                    placeholder="请输入验证码"
                    class="input_psd"
                  ></el-input>
                  <div class="img_code" @click="gCaptchaUrl">
                    <img :src="graphicalUrl" alt="" />
                  </div>
                </div>
              </el-form-item>
            </el-form>

            <!-- 短信登录 -->
            <el-form
              :model="noteForm"
              :rules="rules"
              ref="noteForm"
              class="demo_ruleForm"
              v-show="actives_val == 1"
            >
              <el-form-item prop="mobile">
                <el-input
                  prefix-icon="el-icon-mobile-phone"
                  v-model="noteForm.mobile"
                  placeholder="请输入您的手机号"
                ></el-input>
              </el-form-item>

              <el-form-item prop="codeNote" class="captcha_t">
                <el-input
                  placeholder="请输入验证码"
                  v-model="noteForm.codeNote"
                >
                </el-input>
                <el-button
                  type="primary"
                  size="small"
                  round
                  @click="sendCode(noteForm)"
                  :disabled="counter > 0 ? true : false"
                >
                  {{
                    counter > 0
                      ? `${counter < 10 ? '0' : ''}${counter} 秒后重新发送`
                      : '发送验证码'
                  }}
                </el-button>
              </el-form-item>
            </el-form>

            <el-button
              type="primary"
              size="medium"
              round
              style="width: 100%"
              @click="submitForm(ruleForm, noteForm)"
            >
              登录
            </el-button>
            <div class="login_texts">
              登录即同意
              <span @click="agreement">
                《融跃用户服务协议》《融跃教育隐私协议》。
              </span>
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 23px;
              "
            >
              <div
                style="line-height: 32px; cursor: pointer"
                @click="loginShow = 2"
              >
                <a href="javascript:void(0);">忘记密码？</a>
                <!-- <a :href="`#/login/ForgetPsd`">忘记密码？</a> -->
              </div>

              <div>
                还没有账号？
                <span
                  style="color: #388eff; cursor: pointer"
                  @click="loginShow = 3"
                >
                  <a href="javascript:void(0);"> 立即注册 </a>
                  <!-- <a :href="`#/login/register`"> 立即注册 </a> -->
                </span>
              </div>
            </div>
          </div>
        </div>
        <!-- 找回密码 -->
        <ForgetPsd ref="forgetpsd" v-if="loginShow == 2"></ForgetPsd>
        <!-- 注册 -->
        <register ref="register" v-if="loginShow == 3"></register>
      </div>
    </div>

    <!-- 协议 -->
    <agreement ref="agreementRefs"></agreement>
  </div>
</template>

<script>
import agreement from './comms/agreement.vue'
import ForgetPsd from './comms/ForgetPsd.vue'
import register from './comms/register.vue'
import { getLogin, getSendSms, getMobilelogin } from '@/api/login.js'

export default {
  name: 'loginPage',
  components: {
    agreement,
    register,
    ForgetPsd
  },
  data() {
    return {
      loginShow: 1,
      ruleForm: {
        mobile: '',
        password: '',
        graphCode: ''
      },
      rules: {
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            pattern: /^1[3-9]\d{9}$/,
            message: '手机号格式不正确',
            trigger: 'blur'
          }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            pattern: /^[\s\S]{6,18}$/,
            message: '密码需要8-24个字符',
            trigger: 'blur'
          }
        ],
        graphCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          {
            pattern: /^[a-zA-Z0-9]{4}$/,
            message: '验证码需要4个字符',
            trigger: 'blur'
          }
        ],
        codeNote: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          {
            pattern: /^\d{6}$/,
            message: '验证码不正确',
            trigger: 'blur'
          }
        ]
      },
      graphicalUrl: '',
      floatingNumber: '',
      // 短信
      noteForm: {
        mobile: '',
        codeNote: ''
      },
      counter: 0,
      actives_val: 0
    }
  },
  created() {
    this.generateFloatWith16DecimalPlaces()
    this.gCaptchaUrl()
  },
  methods: {
    closeLogin() {
      this.$store.state.showLoginStatus = false
    },
    sendCode(rulValue) {
      let mobileResX = /^1[3-9]\d{9}$/
      if (rulValue.mobile) {
        if (mobileResX.test(rulValue.mobile)) {
          this.counter = 60
          let timer = setInterval(() => {
            this.counter--
            if (this.counter <= 0) {
              clearInterval(timer)
              this.counter = 0
            }
          }, 1000)

          let send = {
            event: 'mobilelogin',
            mobile: rulValue.mobile
          }

          getSendSms(send).then(({ data: data }) => {
            this.$message.success({
              type: 'success',
              iconClass: ' ', // 这个会覆盖图标类名，为了实现去掉图标的操作
              customClass: 'el-message--success',
              message: data.msg
            })
          })
        } else {
          this.$message.warning({
            type: 'warning',
            iconClass: ' ', // 这个会覆盖图标类名，为了实现去掉图标的操作
            customClass: 'el-message--warning',
            message: '请输入正确的手机号'
          })
        }
      } else {
        this.$message.warning({
          type: 'warning',
          iconClass: ' ', // 这个会覆盖图标类名，为了实现去掉图标的操作
          customClass: 'el-message--warning',
          message: '手机号不能为空'
        })
        return
      }
    },
    submitForm(rulVal, noteVal) {
      if (this.actives_val == 0) {
        // 账号登录
        console.log(rulVal, '账号登录', this.ruleForm)
        this.$refs.ruleForm.validate(async (valid, valMsg) => {
          if (!valid) {
            return
          }
          let form_login = {
            password: rulVal.password,
            captcha: rulVal.graphCode,
            account: rulVal.mobile
          }
          const { data: data } = await getLogin(form_login)
          if (data.code == 1) {
            localStorage.setItem('vuex_token', data.data.userinfo.token)
            this.$store.state.showLoginStatus = false
            // layer.msg('登录成功')
            this.$message.success({
              message: data.msg,
              type: 'success',
              iconClass: ' ', // 这个会覆盖图标类名，为了实现去掉图标的操作
              customClass: 'el-message--success' // 这个是由于上面设置了iconClass会覆盖掉type，所以需要再设置
            })
            this.$router.push({ path: '/homes_tabs' })

            setTimeout(() => {
              this.refreshT()
            }, 500)
          } else {
            this.$message.error({
              message: data.msg,
              type: 'error',
              iconClass: ' ', // 这个会覆盖图标类名，为了实现去掉图标的操作
              customClass: 'el-message--error' // 这个是由于上面设置了iconClass会覆盖掉type，所以需要再设置
            })
          }
        })
      } else if (this.actives_val == 1) {
        this.$refs.noteForm.validate(async (valid, valMsg) => {
          if (!valid) {
            return
          }
          // 短信登录
          let note_params = {
            captcha: noteVal.codeNote,
            mobile: noteVal.mobile
          }
          const { data: data } = await getMobilelogin(note_params)
          if (data.code == 1) {
            localStorage.setItem('vuex_token', data.data.userinfo.token)
            // layer.msg('登录成功')
            this.$message.success({
              message: data.msg,
              type: 'success',
              iconClass: ' ', // 这个会覆盖图标类名，为了实现去掉图标的操作
              customClass: 'el-message--success' // 这个是由于上面设置了iconClass会覆盖掉type，所以需要再设置
            })
            this.$router.push({ path: '/homes_tabs' })
            this.refreshT()
          } else {
            this.$message.error({
              message: data.msg,
              type: 'error',
              iconClass: ' ', // 这个会覆盖图标类名，为了实现去掉图标的操作
              customClass: 'el-message--error' // 这个是由于上面设置了iconClass会覆盖掉type，所以需要再设置
            })
          }

          // console.log(noteVal, '短信登录', this.noteForm)/
        })
      }
    },
    gCaptchaUrl() {
      this.generateFloatWith16DecimalPlaces()
      fetch(
        `https://rongyue.rongyuejiaoyu.com/api/v1.common/captcha?id=${this.floatingNumber}`
      )
        .then((response) => response.blob())
        .then((blob) => {
          console.log(blob)
          this.graphicalUrl = URL.createObjectURL(blob)
        })
        .catch((error) => {
          console.error('验证码', error)
        })
    },
    agreement() {
      // 协议
      this.$refs.agreementRefs.agreement()
    },
    generateFloatWith16DecimalPlaces() {
      // 生成一个随机数，范围在0到1之间（不包括1）
      let randomFloat = Math.random()
      // 使用toFixed方法将浮点数格式化为字符串，保留16位小数
      let formattedFloat = randomFloat.toFixed(16)
      this.floatingNumber = formattedFloat
      // 输出或返回格式化后的字符串
      console.log(formattedFloat)
      return formattedFloat
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/loginCommon.scss';

/* .content {
  position: relative;
  width: 100vw;
  height: 100vh;
}
.login_box {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 950px;
  height: 500px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  box-shadow: 0px 0px 5px #777;
  .login_left {
    padding: 20px;
    width: 475px;
    height: 500px;
    background: #74b9ff;
    box-sizing: border-box;
  }

  .login_right {
    position: relative;
    width: 475px;
    height: 500px;
    background: url('@/assets/images/ybbg.jpg') no-repeat center center;

    .login_form {
      padding: 10px 32px;
      width: 350px;
      height: 440px;
      background: #ffffff;
      box-shadow: 0 0 8px #cccccc;
      border-radius: 8px;
      position: absolute;
      box-sizing: border-box;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      .nav_tabs {
        display: flex;
        justify-content: space-between;
        text-align: center;

        .actives {
          color: #388eff;
          border-bottom: 4px solid #388eff;
        }

        li {
          width: 40%;
          padding: 0px 0;
          font-size: 17px;
          cursor: pointer;
          font-weight: bold;

          span {
            color: #333;
            display: block;
            height: 45px;
            line-height: 45px;
          }
        }
      }

      .tab_main {
        height: 360px;

        .login_texts {
          width: 100%;
          margin-top: 10px;
          color: #666;
          text-align: justify;

          span {
            color: #333333;
            text-decoration: none;
            cursor: pointer;

            &:hover {
              color: #007bff;
              text-decoration: none;
            }
          }
        }

        .demo_ruleForm {
          padding-top: 34px;

          .captcha_t {
            ::v-deep {
              .el-form-item__content {
                display: flex;
                align-items: center;
                justify-content: space-between;
              }

              .el-button--small.is-round {
                padding: 11px 15px;
              }
            }
          }

          ::v-deep {
            .el-button.is-disabled,
            .el-button.is-disabled:hover {
              cursor: auto;
            }
          }

          .verification {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .input_psd {
              flex: 4;
              margin-right: 12px;
            }
            .img_code {
              flex: 2;

              img {
                width: 100%;
                height: 100%;
                border-radius: 5px;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
} */
</style>
