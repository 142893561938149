/* 首页模块页面路由 */

let homePage = [
  {
    path: '/home',
    name: 'home',
    meta: { title: '融跃教育' },
    component: () => import('@/views/home/index.vue')
  },
  {
    path: '/course',
    name: 'course',
    meta: { title: '所有课程更多' },
    component: () => import('@/views/course/index.vue')
  },
  {
    path: '/consult/combos',
    name: 'combos',
    meta: { title: 'combos图书' },
    component: () => import('@/views/consult/common/combo.vue')
  },
  {
    path: '/bookList',
    name: 'bookList',
    meta: { title: '图书book' },
    component: () => import('@/views/courseCenter/bookList.vue')
  },
  {
    path: '/courseCenter',
    name: 'courseCenter',
    meta: { title: '所有课程' },
    component: () => import('@/views/courseCenter/index.vue')
  },
  {
    path: '/exam_practice',
    name: 'exam_practice',
    meta: { title: '题库列表' },
    component: () => import('@/views/exam_practice/index.vue')
  },
  {
    path: '/lesson_live',
    name: 'lesson_live',
    meta: { title: '直播课程' },
    component: () => import('@/views/lesson_live/index.vue')
  },
  {
    path: '/article_news',
    name: 'article_news',
    meta: { title: '咨询' },
    component: () => import('@/views/article_news/index.vue')
  },
  {
    path: '/download_datum',
    name: 'download_datum',
    meta: { title: '资料下载' },
    component: () => import('@/views/download/index.vue')
  },
  {
    path: '/activity',
    name: 'activity',
    meta: { title: '线下活动' },
    component: () => import('@/views/xa_activity/index.vue')
  },
  {
    path: '/audition',
    name: 'audition',
    meta: { title: '试听' },
    component: () => import('@/views/audition/index.vue')
  },
  {
    path: '/question',
    name: 'question',
    meta: { title: '平台答疑' },
    component: () => import('@/views/consult/question/index.vue')
  },
  {
    path: '/homes_tabs',
    name: 'homes_tabs',
    meta: { title: '会员中心' },
    component: () => import('@/views/homes_tabs/index.vue')
  },
  {
    path: '/handpick_kc',
    name: 'handpick_kc',
    meta: { title: '课程详情' },
    component: () => import('@/views/homes_comms/handpick_kc/index.vue')
  },
  {
    path: '/ShiTingDetail',
    name: 'ShiTingDetail',
    meta: { title: '试听详情' },
    component: () => import('@/views/audition/ShiTingDetail.vue')
  },
  {
    path: '/book_detail',
    name: 'book_detail',
    meta: { title: '图书详情' },
    component: () => import('@/views/homes_comms/book_detail/index.vue')
  },
  {
    path: '/hot_live',
    name: 'hot_live',
    meta: { title: '直播详情页' },
    component: () => import('@/views/homes_comms/hot_live/index.vue')
  },
  {
    path: '/down_data',
    name: 'down_data',
    meta: { title: '资料下载详情' },
    component: () => import('@/views/homes_comms/down_data/index.vue')
  },
  {
    path: '/top_teacher',
    name: 'top_teacher',
    meta: { title: '名师' },
    component: () => import('@/views/homes_comms/top_teacher/index.vue')
  },
  {
    path: '/teacher_team',
    name: 'teacher_team',
    meta: { title: '名师团队' },
    component: () => import('@/views/homes_comms/teacher_team/index.vue')
  },
  {
    path: '/exam_practice/CFA/questionbank',
    name: 'questionbank',
    meta: { title: '题库列表' },
    component: () => import('@/views/exam_practice/CFA/questionbank.vue')
  },
  {
    path: '/exam_practice/CFA/cfa_tiku_pratice',
    name: 'cfa_tiku_pratice',
    meta: { title: 'cfa_tiku_pratice做题' },
    component: () => import('@/views/exam_practice/CFA/cfa_tiku_pratice.vue')
  },
  {
    path: '/exam_practice/CFA/CFAReport',
    name: 'CFAReport',
    meta: { title: 'cfa题库报告页' },
    component: () => import('@/views/exam_practice/CFA/CFAReport.vue')
  },
  {
    path: '/exam_practice/CFA/simulation_jk',
    name: 'simulation_jk',
    meta: { title: 'cfa_jk机考列表' },
    component: () => import('@/views/exam_practice/CFA/simulation_jk.vue')
  },
  {
    path: '/exam_practice/FRM/frmQuestionbank',
    name: 'frmQuestionbank',
    meta: { title: '题库列表' },
    component: () => import('@/views/exam_practice/FRM/frmQuestionbank.vue')
  },
  {
    path: '/exam_practice/FRM',
    name: 'frmPractice',
    meta: { title: 'frmPractice' },
    component: () => import('@/views/exam_practice/FRM/index.vue')
  },
  {
    path: '/exam_practice/FRM/frmReport',
    name: 'frmReport',
    meta: { title: 'frmReport报告页' },
    component: () => import('@/views/exam_practice/FRM/frmReport.vue')
  },
  {
    path: '/exam_practice/FRM/frmAnalysis',
    name: 'frmAnalysis',
    meta: { title: 'frmAnalysis解析页' },
    component: () => import('@/views/exam_practice/FRM/frmAnalysis.vue')
  },
  {
    path: '/exam_practice/FRM/Frmcomputer',
    name: 'Frmcomputer',
    meta: { title: 'Frmcomputer机考' },
    component: () => import('@/views/exam_practice/FRM/Frmcomputer.vue')
  },
  {
    path: '/exam_practice/ACCA/AccaQuestionbank',
    name: 'ACCAQuestionbank',
    meta: { title: 'ACCA列表页' },
    component: () => import('@/views/exam_practice/ACCA/AccaQuestionbank.vue')
  },
  {
    path: '/exam_practice/ACCA/ACCAreport',
    name: 'ACCAreport',
    meta: { title: 'Acca题库报告页' },
    component: () => import('@/views/exam_practice/ACCA/ACCAreport.vue')
  },
  {
    path: '/exam_practice/ACCA/ACCAnalysis',
    name: 'ACCAnalysis',
    meta: { title: 'ACCAnalysis' },
    component: () => import('@/views/exam_practice/ACCA/ACCAnalysis.vue')
  },
  {
    path: '/exam_practice/CPA',
    name: 'CPAPage',
    meta: { title: 'CPAPage' },
    component: () => import('@/views/exam_practice/CPA/index.vue')
  },
  {
    path: '/exam_practice/CPA/cpa_questionbank',
    name: 'cpa_questionbank',
    meta: { title: 'cpa_questionbank题库' },
    component: () => import('@/views/exam_practice/CPA/cpa_questionbank.vue')
  },
  {
    path: '/exam_practice/common/Cpacomputer',
    name: 'Cpacomputer',
    meta: { title: 'Cpacomputer机考列表' },
    component: () => import('@/views/exam_practice/CPA/common/Cpacomputer.vue')
  },
  {
    path: '/exam_practice/CMA',
    name: 'CMAPage',
    meta: { title: 'CMAPage页' },
    component: () => import('@/views/exam_practice/CMA/index.vue')
  },
  {
    path: '/exam_practice/CMA/cma_questionbank',
    name: 'cma_questionbank',
    meta: { title: 'cma_questionbank列表页' },
    component: () => import('@/views/exam_practice/CMA/cma_questionbank.vue')
  },
  {
    path: '/exam_practice/CMA/Cmacomputer',
    name: 'Cmacomputer',
    meta: { title: 'Cmacomputer机考' },
    component: () => import('@/views/exam_practice/CMA/Cmacomputer.vue')
  },
  {
    path: '/exam_practice/CMA/NewCmaTest',
    name: 'NewCmaTest',
    meta: { title: 'NewCmaTest试题' },
    component: () => import('@/views/exam_practice/CMA/NewCmaTest.vue')
  },
  {
    path: '/exam_practice/Python',
    name: 'PythonPage',
    meta: { title: 'PythonPage页' },
    component: () => import('@/views/exam_practice/Python/index.vue')
  },
  {
    path: '/exam_practice/Python/Python_questionbank',
    name: 'Python_questionbank',
    meta: { title: 'Python_questionbank列表页' },
    component: () => import('@/views/exam_practice/Python/Python_questionbank.vue')
  },
  {
    path: '/exam_practice/Accountancy_qualification',
    name: 'Accountancy_qualification_Page',
    meta: { title: 'Accountancy_qualificationPage页' },
    component: () => import('@/views/exam_practice/Accountancy_qualification/index.vue')
  },
  {
    path: '/exam_practice/Accountancy_qualification/questionbank',
    name: 'Accountancy_qualification_questionbank',
    meta: { title: 'questionbank列表页' },
    component: () => import('@/views/exam_practice/Accountancy_qualification/questionbank.vue')
  },
  {
    path: '/exam_practice/errorList',
    name: 'errorList',
    meta: { title: 'errorList错题集' },
    component: () => import('@/views/exam_practice/errorList/index.vue')
  },
  {
    path: '/exam_practice/errorList/collectionDetails',
    name: 'collectionDetails',
    meta: { title: 'errorList错题集' },
    component: () => import('@/views/exam_practice/errorList/collectionDetails.vue')
  },
  {
    path: '/exam_practice/errorReport',
    name: 'errorReport',
    meta: { title: '错题报告页' },
    component: () => import('@/views/exam_practice/errorList/errorReport.vue')
  },
  {
    path: '/exam_practice/errorAnalysis',
    name: 'errorAnalysis',
    meta: { title: '错题解析页' },
    component: () => import('@/views/exam_practice/errorList/errorAnalysis.vue')
  },
  {
    path: '/exam_practice/proErrorClass',
    name: 'proErrorClass',
    meta: { title: '错题列表' },
    component: () => import('@/views/exam_practice/errorList/proErrorClass.vue')
  },
  {
    path: '/exam_practice/collectionMenu',
    name: 'collectionMenu',
    meta: { title: '收藏列表' },
    component: () => import('@/views/exam_practice/errorList/collectionMenu.vue')
  },
  {
    path: '/exam_practice/collectList',
    name: 'collectList',
    meta: { title: 'collectList收藏集' },
    component: () => import('@/views/exam_practice/collectList/index.vue')
  },
  {
    path: '/exam_practice/tikuMockLists',
    name: 'tikuMockLists',
    meta: { title: 'tikuMockLists模拟考试列表' },
    component: () => import('@/views/exam_practice/errorList/tikuMockLists.vue')
  },
  {
    path: '/exam_practice/tiKuMoke/FRM/frmtestReport',
    name: 'tikufrmtestReport',
    meta: { title: 'tiku模拟考试报告' },
    component: () => import('@/views/exam_practice/tiKuMoke/FRM/frmtestReport.vue')
  },
  {
    path: '/exam_practice/tiKuMoke/FRM/frmMockAnalysis',
    name: 'tikufrmMockAnalysis',
    meta: { title: 'tiku模拟考试解析' },
    component: () => import('@/views/exam_practice/tiKuMoke/FRM/frmMockAnalysis.vue')
  },
  {
    path: '/exam_practice/smartTest',
    name: 'smartTest',
    meta: { title: 'smartTest智能组卷' },
    component: () => import('@/views/exam_practice/smartTest.vue')
  },
  {
    path: '/lineCourseList',
    name: 'lineCourseList',
    meta: { title: 'lineCourseList长线课程' },
    component: () => import('@/views/lineCourseList/index.vue')
  },
  {
    path: '/confirm_order_combo',
    name: 'confirm_order_combo',
    meta: { title: '订单详情' },
    component: () => import('@/views/common/confirm_order_combo.vue')
  },
  {
    path: '/order_Book',
    name: 'order_Book',
    meta: { title: '图书订单' },
    component: () => import('@/views/common/order_Book.vue')
  },
  {
    path: '/pay_combo',
    name: 'pay_combo',
    meta: { title: '提交订单' },
    component: () => import('@/views/common/pay_combo.vue')
  }
]

export default {
  homePage
}
