/*  咨询模块页面 */
let consult = [
  {
    path: 'CFA',
    name: 'CFAPage',
    meta: { title: 'CFAPage咨询页' },
    component: () => import('@/views/consult/CFA/index.vue')
  },
  {
    path: 'examCourse',
    name: 'examCourse',
    meta: { title: '咨询列表' },
    component: () => import('@/views/consult/common/examCourse.vue')
  },
  {
    path: '/articleDetails',
    name: 'articleDetails',
    meta: { title: '咨询详情' },
    component: () => import('@/views/consult/common/articleDetails.vue')
  },
  {
    path: 'books',
    name: 'books',
    meta: { title: 'books图书' },
    component: () => import('@/views/consult/CFA/books.vue')
  },
  {
    path: 'CMA',
    name: 'CMA',
    meta: { title: 'CMAPage咨询' },
    component: () => import('@/views/consult/CMA/index.vue')
  },
  {
    path: 'FRM',
    name: 'FRM',
    meta: { title: 'FRMPage咨询' },
    component: () => import('@/views/consult/FRM/index.vue')
  },
  {
    path: 'ACCA',
    name: 'ACCA',
    meta: { title: 'ACCAPage咨询' },
    component: () => import('@/views/consult/ACCA/index.vue')
  },
  {
    path: 'AccaMean',
    name: 'AccaMean',
    meta: { title: 'ACCA是什么' },
    component: () => import('@/views/consult/ACCA/AccaMean.vue')
  },
  {
    path: 'CPA',
    name: 'CPA',
    meta: { title: 'CPAPage咨询' },
    component: () => import('@/views/consult/CPA/index.vue')
  },
  {
    path: 'CGFT',
    name: 'CGFT',
    meta: { title: 'CGFTPage咨询' },
    component: () => import('@/views/consult/CGFT/index.vue')
  },
  {
    path: 'PCMA',
    name: 'PCMA',
    meta: { title: 'PCMAPage咨询' },
    component: () => import('@/views/consult/PCMA/index.vue')
  },
  {
    path: 'primaryPost',
    name: 'primaryPost',
    meta: { title: 'primaryPost咨询' },
    component: () => import('@/views/consult/primaryPost/index.vue')
  },
  {
    path: 'answerCenter',
    name: 'answerCenter',
    meta: { title: '问答中心' },
    component: () => import('@/views/consult/question/answerCenter.vue')
  },
  {
    path: 'detailsAnswer',
    name: 'detailsAnswer',
    meta: { title: '答疑详情' },
    component: () => import('@/views/consult/question/detailsAnswer.vue')
  }
]

export default {
  consult
}
